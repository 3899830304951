import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import fbinit from 'vue-faui-user-fe2/fbinitCDN'
import initialize from 'vue-faui-user-fe2/initialize'
//import firebase from 'firebase'

Vue.config.productionTip = false

fbinit.fbinit()
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
/*
const firebaseConfig = {
  apiKey: "AIzaSyC-ka9au9Sl7B6x3UfA0k9svvndCwigH7M",
  authDomain: "rhizosphere-d0163.firebaseapp.com",
  projectId: "rhizosphere-d0163",
  storageBucket: "rhizosphere-d0163.appspot.com",
  messagingSenderId: "367978069841",
  appId: "1:367978069841:web:5c62158ff58f95ae025d70",
  measurementId: "G-74WDBX3LVN"
};
console.log(firebaseConfig)
Vue.prototype.$firebase = firebase.initializeApp(firebaseConfig);
console.log("Vue.prototype.$firebase", Vue.prototype.$firebase)
*/

const get_browser_lang = () => {
  const browser_lang = navigator.language.toLowerCase();
  switch(true){
//    case "ja-jp":
//    case "ja":
    case /ja.*/.test(browser_lang):
      return "ja";
    case /zh.*/.test(browser_lang):
      return "zh";
//      break;
//    case "zh-CN"
    default:
      return "en";
//      break;
  }
};

// https://stackoverflow.com/questions/49256765/change-vue-prototype-variable-in-all-components
let globalData = new Vue({
  data: { 
    // initial values
    $lang: get_browser_lang(),
/*
    $isLogin: false,
    $isEmailVerified: false,
    $internalUserId: "",
*/
    $hyphas: [],
//    $selected_hypha: "",
    $hypha: {
      id: "",
      interval: "",
      latestConnection: "",
    },
    $hyphalastupdated: 0,
    $buttons: "",
    
/*
    $stripe: process.env.VUE_APP_stripeEnv == "live" ? process.env.VUE_APP_stripeapiLiveKey : process.env.VUE_APP_stripeapiTestKey,
    $stripeEnv: process.env.VUE_APP_stripeEnv,
    $koshinto: process.env.VUE_APP_koshintoProtocol + "://" 
               + process.env.VUE_APP_koshintoHost +"."
               + process.env.VUE_APP_koshintoDomain,
*/
    $server: process.env.VUE_APP_serverProtocol + "://" 
             + process.env.VUE_APP_serverHost +"."
             + process.env.VUE_APP_serverDomain,
  },

});
Vue.mixin({
  computed: {
    $lang: {
      get: function () { return globalData.$data.$lang },
      set: function (newLang) { globalData.$data.$lang = newLang; }
    },
/*
    $isLogin: {
      get: function () { return globalData.$data.$isLogin },
      set: function (newIsLogin) { globalData.$data.$isLogin = newIsLogin; }
    },
    $isEmailVerified: {
      get: function () { return globalData.$data.$isEmailVerified },
      set: function (newIsEmailVerified) { globalData.$data.$isEmailVerified = newIsEmailVerified; }
    },
    $internalUserId: {
      get: function () { return globalData.$data.$internalUserId },
      set: function (newInternalUserId) { globalData.$data.$internalUserId = newInternalUserId; }
    },
    $displayName: {
      get: function () { return globalData.$data.$displayName },
      set: function (newDisplayName) { globalData.$data.$displayName = newDisplayName; }
    },
    $user_email: {
      get: function () { return globalData.$data.$user_email },
      set: function (newUser_email) { globalData.$data.$user_email = newUser_email; }
    },
    $user_id: {
      get: function () { return globalData.$data.$user_id },
      set: function (newUser_id) { globalData.$data.$user_id = newUser_id; }
    },
*/
    $hyphas: {
      get: function () { return globalData.$data.$hyphas },
      set: function (newHyphas) { globalData.$data.$hyphas = newHyphas; }
    },/*
    $selected_hypha: {
      get: function () { return globalData.$data.$selected_hypha },
      set: function (newSelected_hypha) { globalData.$data.$selected_hypha = newSelected_hypha; }
    },*/
    $hypha: {
      get: function () { return globalData.$data.$hypha },
      set: function (newHypha) { globalData.$data.$hypha = newHypha; }
    },
    $hyphalastupdated: {
      get: function () { return globalData.$data.$hyphalastupdated },
      set: function (newHyphalastupdated) { globalData.$data.$hyphalastupdated = newHyphalastupdated; }
    },
    $buttons: {
      get: function () { return globalData.$data.$buttons },
      set: function (newButtons) { globalData.$data.$buttons = newButtons; }
    },
    // for ue-faui-user-fe2
    $server: {
      get: function () { return globalData.$data.$server },
    },
    ...initialize.accountinit(),
    ...initialize.purchaseinit()
/*
    $stripe: {
      get: function () { return globalData.$data.$stripe },
    },
    $stripeEnv: {
      get: function () { return globalData.$data.$stripeEnv },
    },
    $koshinto: {
      get: function () { return globalData.$data.$koshinto },
    },
*/
  }
})

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
