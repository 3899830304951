<template>
  <v-app>

    <Navbar :links="links">
      <template v-slot:title>
        <v-toolbar-title class="grey--text">
          <span>Rhizo</span>
          <span class="font-weight-light">Sphere</span>
        </v-toolbar-title>
      </template>
      
      <template v-slot:menu>
        <v-spacer></v-spacer>

        <!-- logged in icon -->
        <v-tooltip v-if="$isLogin" bottom>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" class="grey--text">person_outline</v-icon>
          </template>
          <span>Logged in as {{ $displayName }}</span>
        </v-tooltip>

        <!-- dropdown menu -->
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
          <!-- <v-btn text slot="activator"> -->
          <v-btn text v-on="on">
            <sup>hypha</sup><v-icon left>expand_more</v-icon>
            <span>{{$hypha.id}}</span>
          </v-btn>
          </template>
          <v-list>
            <!-- v-list-tile is changed to v-list-item -->
            <v-list-item v-for="hypha in $hyphas" :key="hypha" @click="selectHypha(hypha)">
              <v-list-item-title>{{ hypha }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </Navbar>
    <Login/>

    <v-content>
      <router-view/>
    </v-content>
    <Status/>

    <Footer/>
  </v-app>
</template>

<script>
import {Navbar} from 'vuetify-nav'
import {AccountRoutesCDN  as AccountRoutes} from 'vue-faui-user-fe2/accountCDN.js'
import {PurchaseRoutes as PurchaseRoutes} from 'vue-faui-user-fe2/purchase.js'
import {LoginCDN as Login} from 'vue-faui-user-fe2/loginCDN'
//import Navbar from '@/components/Navbar'
import Footer from '@/components/Footer'
//import Login from '@/components/Login.vue'
import Status from '@/views/Status.vue'
//import {AccountRoutes} from 'vue-faui-user-fe/'

import firebase from "firebase";
import "firebase/auth";
//import firebaseui from "firebaseui";
import axios from 'axios';
var sprintf = require('sprintf-js').sprintf
// vsprintf = require('sprintf-js').vsprintf

export default {
  name: 'App',

  components: {
    Navbar,
    Login,
    Footer,
    Status,
  },

  data: () => ({
    //
    links: [
      { icon: 'home', text: 'Home', route: '/'},
      { icon: 'contacts', text: 'About', route: '/about'},
      { icon: 'face', text: 'Dev', route: '/dev'},
      { icon: 'face', text: 'Button', route: '/button'},
      AccountRoutes.menuItem,
      PurchaseRoutes.menuItem,
      { icon: 'menu_book', text: 'Document', route: '/docs'}
    ]
  }),
    created(){
    AccountRoutes.init()
  },
  async mounted() {
    /*    
    let ui = firebaseui.auth.AuthUI.getInstance();
    let uiConfig = {
      signInSuccessUrl: "/",
//      signInOptions: [firebase.auth.FacebookAuthProvider.PROVIDER_ID]
      signInOptions: [firebase.auth.EmailAuthProvider.PROVIDER_ID],
      credentialHelper: firebaseui.auth.CredentialHelper.NONE
    };
*/
//    let user = await firebase.auth().onAuthStateChanged()
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        this.$isLogin = true;
        this.$displayName = user.displayName
        this.$user_email  = user.email
        this.$user_id     = user.uid

        const idToken = await user.getIdToken(); 
/*
        const url = sprintf("https://rhizosphere-sv.uedasoft.com/b/v1/getuser/%s", idToken)
        const res = await axios.get(url)
        if (res.status == 200) {
          console.log("res = ", res.data)
          this.$internalUserId = res.data.id
          this.$hyphas = res.data.hyphas
          this.$hypha.id = this.$hyphas[0]
        }
*/
        const url = sprintf("https://rhizosphere-sv.uedasoft.com/b/v1/gethyphas/%s", idToken)
        const res = await axios.get(url)
        if (res.status == 200) {
          console.log("res = ", res.data)
//          this.$internalUserId = res.data.id
          this.$hyphas = res.data
          this.$hypha.id = this.$hyphas[0]
        }

        const url2 = sprintf("https://rhizosphere-sv.uedasoft.com/b/v1/gethypha/%s/%s",this.$hypha.id, idToken)
        const res2 = await axios.get(url2)
        if (res2.status == 200) {
          console.log("res2 = ", res2.data)
          for (const property in res2.data){
            this.$hypha[property] = res2.data[property]
          }
        }

        const url3 = sprintf("https://rhizosphere-sv.uedasoft.com/b/v1/getbuttons/%s/%s",this.$hypha.id, idToken)
        const res3 = await axios.get(url3)
        if (res3.status == 200) {
          this.$buttons = res3.data.buttons
          console.log("res3 = ", res3.data)
        }
/*
        firebase.auth().onAuthStateChanged(async (user) => {
          if (user) {
            console.log("user.uid",user.uid)
            console.log("this.$hypha.id",this.$hypha.id)
            let db = firebase.firestore();
            db.collection("users").doc(user.uid)
            .collection("hyphas").doc(this.$hypha.id)
            .collection("services").doc("buttons")
            .get().then(docSnapshot =>{
              if (docSnapshot.exists){
                console.log("docSnapshot",docSnapshot)
                console.log("docSnapshot.get(buttons)",docSnapshot.get("buttons"))
                this.$buttons = docSnapshot.get("buttons")
              }
            }).catch(err => alert(err))
          }
        })
      } else {
        if (!ui) {
          ui = new firebaseui.auth.AuthUI(firebase.auth());
          ui.start("#firebaseui-auth-container", uiConfig);
        }
*/
      }
    });
  },
};
</script>
