<template>
  <v-expansion-panels v-if="$isLogin && $isEmailVerified">
    <v-expansion-panel>

      <v-expansion-panel-header >
        Status
      </v-expansion-panel-header>

      <v-expansion-panel-content>
        <v-card-text>
          <p>Hypha status: {{connected ? "connecting" : "disrupting"}}</p>
          <p>Interval setting: {{$hypha.realInterval / 60}} minutes</p>
          <p>Connecting protocol: {{$hypha}} </p>
    <!--      {{$hypha}} -->
          <p>Last access: {{unixtime2datestr($hypha.latestConnection* 1000)}}</p>
          <p>Current time: {{clock}}</p>
          <p v-if="connected">Next access: {{unixtime2datestr(($hypha.latestConnection + $hypha.realInterval)* 1000)}}</p>
        </v-card-text>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import axios from 'axios';
//var sprintf = require('sprintf-js').sprintf//,
//    vsprintf = require('sprintf-js').vsprintf
export default {
  components:{
  },
  data: function () {
    return {
      clock: 0,
      now: 0,
    }
  },methods: {
    updateLatestConnection(){
      axios
      .get('https://rhizosphere-sv.uedasoft.com/b/v1/ishyphaupdated/' + this.$hypha.id + '/' + this.$hyphalastupdated)
      .then(response => {
        if (response.status == 200) {
          console.log("response = ", response.data)
//          this.$hypha.latestConnection = response.data;
          if (Object.prototype.hasOwnProperty.call(response.data, "hyphaupdated")){
            var id = this.$hypha.id
            this.$hypha = response.data.hyphaupdated
            this.$hypha.id = id
            this.$hyphalastupdated = response.data.hyphaupdated.updated
          }
          if (Object.prototype.hasOwnProperty.call(response.data, "hyphalastconnection")){
            this.$hypha.latestConnection = response.data.hyphalastconnection
            if (response.data.hyphalastconnection > this.$hypha.updated){
              this.$hyphalastupdated = response.data.hyphalastconnection
            }
          }
        }
      });
    },

    unixtime2datestr(unixtime){
      return (new Date(unixtime)).toString()
    },

    updateClock(){
      const date = new Date()
      this.clock = date.toString()
      this.now = date.getTime()
    },

  },created(){
    setInterval(this.updateLatestConnection,5000)
    setInterval(this.updateClock,100)
  },computed: {
    connected: function() {
//      const date = new Date()
      return Math.floor(this.now / 1000) - this.$hypha.latestConnection <= this.$hypha.realInterval ? true : false
    }
  }
}
</script>