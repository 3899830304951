<template>
  <div class="home">
    <h2>HOME</h2>
    <v-container v-if="$isLogin && $isEmailVerified">
          <img alt="Vue logo" src="../assets/ueda.png">
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: {
  }
}
</script>
