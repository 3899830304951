export default {
  routes: [
    // document root
    {
      path: '/docs',
      name: 'docs',
      beforeEnter() {location.href = '/docs'}
    },

    // for account
    {
      path: '/docs/users-manual/account',
      name: 'docs-account',
      beforeEnter() {location.href = '/docs/users-manual/account'}
    },
    {
      path: '/docs/users-manual/account/changeemail',
      name: 'docs-account-changeemail',
      beforeEnter() {location.href = '/docs/users-manual/account/changeemail'}
    },
    {
      path: '/docs/users-manual/account/changename',
      name: 'docs-account-changename',
      beforeEnter() {location.href = '/docs/users-manual/account/changename'}
    },
    {
      path: '/docs/users-manual/account/deleteaccount',
      name: 'docs-account-deleteaccount',
      beforeEnter() {location.href = '/docs/users-manual/account/deleteaccount'}
    },
    {
      path: '/docs/users-manual/account/newpassword',
      name: 'docs-account-newpassword',
      beforeEnter() {location.href = '/docs/users-manual/account/newpassword'}
    },
    {
      path: '/docs/users-manual/account/signout',
      name: 'docs-account-signout',
      beforeEnter() {location.href = '/docs/users-manual/account/signout'}
    },

    // for purchase
    {
      path: '/docs/users-manual/purchase',
      name: 'docs-purchase',
      beforeEnter() {location.href = '/docs/users-manual/purchase'}
    },
    {
      path: '/docs/users-manual/purchase/purchasebind',
      name: 'docs-purchase-purchasebind',
      beforeEnter() {location.href = '/docs/users-manual/purchase/purchasebind'}
    },
    {
      path: '/docs/users-manual/purchase/purchasehistory',
      name: 'docs-purchase-purchasehistory',
      beforeEnter() {location.href = '/docs/users-manual/purchase/purchasehistory'}
    },

    // for binds
    {
      path: '/docs/users-manual/binds/',
      name: 'docs-binds',
      beforeEnter() {location.href = '/docs/users-manual/binds/'}
    },
    {
      path: '/docs/users-manual/binds/bindslist',
      name: 'docs-binds-bindslist',
      beforeEnter() {location.href = '/docs/users-manual/binds/bindslist'}
    },
    {
      path: '/docs/users-manual/binds/bindmenu',
      name: 'docs-binds-bindmenu',
      beforeEnter() {location.href = '/docs/users-manual/binds/bindmenu'}
    },
    // for bind edit
    {
      path: '/docs/users-manual/binds/keys',
      name: 'docs-binds-keys',
      beforeEnter() {location.href = '/docs/users-manual/binds/keys'}
    },
    {
      path: '/docs/users-manual/binds/safekeeping',
      name: 'docs-binds-safekeeping',
      beforeEnter() {location.href = '/docs/users-manual/binds/safekeeping'}
    },
    {
      path: '/docs/users-manual/binds/sansilibrarydownload',
      name: 'docs-binds-sansilibrarydownload',
      beforeEnter() {location.href = '/docs/users-manual/binds/sansilibrarydownload'}
    },
    {
      path: '/docs/users-manual/binds/status',
      name: 'docs-binds-status',
      beforeEnter() {location.href = '/docs/users-manual/binds/status'}
    },

  ]
}