import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import ga from 'vue-ga'

import Document from '@/router/document.route.js'
//import Account  from '@/views/account/account.route.js'
import Dev  from '@/views/dev/dev.route.js'
import Button  from '@/views/button/button.route.js'
//import { AccountRoutes, PurchaseRoutes } from "vue-faui-user-fe"
import {AccountRoutesCDN  as AccountRoutes} from 'vue-faui-user-fe2/accountCDN.js'
import {PurchaseRoutesCDN as PurchaseRoutes} from 'vue-faui-user-fe2/purchaseCDN.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },

    // dev
    ...Dev.routes,

    // button
    ...Button.routes,

    // account
    ...AccountRoutes.routes,
//    ...Account.routes,

    // purchase
    ...PurchaseRoutes.routes,

    // document
    ...Document.routes,
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

ga(router, 'UA-43410269-6')

export default router
